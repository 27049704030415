<template>
    <div class="page-content white-bg">
        <div class="layout-bg between">
            <div class="banner-wrap">
                <el-carousel indicator-position="none">
                    <el-carousel-item
                        v-for="(item, index) in bannerList"
                        :key="index"
                    >
                        <img :src="item.img" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="case-wrap">
                <h3>
                    <img src="@/assets/images/adr.png" class="phone" alt="" />
                    <span>湖北</span>
                </h3>
                <div class="num-case-wrap">
                    <h4>已诉调对接案件(件)</h4>
                    <div class="num-case">
                        <ul>
                            <li
                                class="item-num"
                                v-for="(item, index) in numList"
                                :key="index"
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                    <div class="center">
                        <img
                            class="case-img hand"
                            @click="skipCase"
                            src="@/assets/images/case-btn.png"
                            alt=""
                        />
                    </div>
                    <div class="center">
                        <img
                            class="phone-img"
                            src="@/assets/images/phone.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="highlights-wrap center">
            <ul class="list between">
                <li v-for="(item, index) in highlightsData" :key="index">
                    <span class="present-icon">
                        <img :src="item.img" />
                    </span>
                    <div class="name">
                        <span>{{ item.title }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 关于我们 -->
        <div class="module-box business-flow" style="margin-top: 50px">
            <div class="box-title">
                <h4>关于我们</h4>
            </div>
            <div
                class="progress-wrap"
                style="
                    text-align: left;
                    text-indent: 2em;
                    padding-left: 10px;
                    padding-right: 10px;
                "
            >
                <p>
                    麻城市和信金融纠纷调解中心是由麻城市司法局批准，在麻城市民政局依法注册登记民办非企业的专业金融纠纷调解组织，
                </p>
                <p>
                    麻城市和信金融纠纷调解中心业务范围是：依照《最高人民法院、中国人民银行、中国银行保险监督管理委员会关于全面推进金融纠纷多元化解机制建设的意见》规定，依法受理调解平等民商事主体之间因金融业务产生的合同和侵权责任纠纷，以及开展金融法律知识宣传、经济纠纷调解的理论研究和法律咨询服务等。
                </p>
                <p>
                    麻城市和信金融纠纷调解中心的调解对象是：依法依规设立的持牌金融机构和地方金融组织与其客户之间的民事纠纷。金融机构必须为经人民银行、银保监会、证监会及各省地方金融监管局批准设立的合规持牌金融机构、地方金融组织，未经批准擅自开展金融业务产生的纠纷，不属于受理调解的范围。
                </p>
                <p>
                    麻城市和信金融纠纷调解中心调解原则和调解方式：按照调解自愿和依法公正原则，运用网络信息调解、电话调解、现场调解、远程视频调解的方式，稳妥化解金融民事纠纷。
                </p>
            </div>
        </div>

        <!-- 调解流程 -->
        <div class="module-box business-flow">
            <div class="box-title"><h4>金融纠纷调解流程</h4></div>
            <div class="progress-wrap">
                <img src="@/assets/images/tjlc.png" />
            </div>
        </div>
        <div class="module-box business-flow">
            <div class="box-title"><h4>金融纠纷诉调对接流程</h4></div>
            <div class="progress-wrap">
                <img src="@/assets/images/djlc.png" />
            </div>
        </div>
        <div class="module-box business-flow">
            <div class="box-title"><h4>金融纠纷审判流程</h4></div>
            <div class="progress-wrap">
                <img src="@/assets/images/splc.png" />
            </div>
        </div>
        <div class="module-box business-flow">
            <div class="box-title"><h4>金融纠纷强制执行流程</h4></div>
            <div class="progress-wrap">
                <img src="@/assets/images/zxlc.png" />
            </div>
        </div>

        <div class="courtmediate-wrap clearfix flex">
            <div class="module-box mediate-guide" style="width: 580px">
                <div class="box-title">
                    <a class="more hand" @click="skipfwzn('')">查看更多 &gt;</a>
                    <h4>诉讼服务指南</h4>
                </div>
                <div class="guide-list-more">
                    <ul class="clearfix newBoxHome">
                        <li
                            v-for="(item, index) in fwzlList"
                            :key="index"
                            @click="skipfwzn(item.id)"
                        >
                            <span>{{ item.createTime }}</span>
                            <a class="text_list">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="module-box mediate-guide"
                style="width: 580px; float: right"
            >
                <div class="box-title">
                    <a class="more hand" @click="skipNav('')">查看更多 &gt;</a>
                    <h4>调解指南</h4>
                </div>
                <div class="guide-list-more">
                    <ul class="clearfix newBoxHome">
                        <li
                            v-for="(item, index) in tjzlList"
                            :key="index"
                            @click="skipNav(item.id)"
                        >
                            <span>{{ item.createTime }}</span>
                            <a class="text_list">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "home",
    data() {
        return {
            bannerList: [
                {
                    img: require("@/assets/images/banner01.jpg"),
                },
                {
                    img: require("@/assets/images/banner02.jpg"),
                },
                {
                    img: require("@/assets/images/banner03.jpeg"),
                },
                {
                    img: require("@/assets/images/banner04.jpeg"),
                },
                {
                    img: require("@/assets/images/banner05.jpg"),
                },
            ],
            numList: ["1", "6", "7", "2", "3", "5"],
            highlightsData: [
                {
                    img: require("@/assets/images/tj.png"),
                    title: "调解",
                },
                {
                    img: require("@/assets/images/la.png"),
                    title: "立案",
                },
                {
                    img: require("@/assets/images/pj.png"),
                    title: "判决",
                },
                {
                    img: require("@/assets/images/zx.png"),
                    title: "执行",
                },
            ],
            fwzlList: [],
            tjzlList: [],
        };
    },
    async created() {
        this.getfwzlData(); //获取诉讼服务指南
        this.gettjzlData();
    },
    methods: {
        // 查询案件
        skipCase() {
            this.$router.push("/case/index");
        },
        // 讼诉服务指南
        skipfwzn(id) {
            if (id) {
                return this.$router.push({ path: "/see-more", query: { id: id } });
            }
            this.$router.push("/see-more");
        },
        // 调解指南
        skipNav(id) {
            if (id) {
                return this.$router.push({
                    path: "/mediation-guide",
                    query: { id: id },
                });
            }
            this.$router.push("/mediation-guide");
        },

        async getfwzlData() {
            let params = {
                pageSize: 8,
                pageNum: 1,
                typeId: "10000058",
            };
            const rsp = await this.$http("getNewsList", params);
            if (rsp.code != 200)
                return this.$message({ message: rsp.message, type: "warning" });
            this.fwzlList = rsp.data.records;
        },
        async gettjzlData() {
            let params = {
                pageSize: 8,
                pageNum: 1,
                typeId: "10000055",
            };
            const rsp = await this.$http("getNewsList", params);
            if (rsp.code != 200)
                return this.$message({ message: rsp.message, type: "warning" });
            this.tjzlList = rsp.data.records;
        },
    },
};
</script>
<style lang="less" scoped>
.page-content {
    width: 1180px;
    margin: 0 auto 20px;
    padding: 20px;
    .layout-bg {
        .banner-wrap {
            width: 810px;
            height: 446px;
            /deep/ .el-carousel__container {
                height: 446px !important;
            }
            .el-carousel__item img {
                width: 100%;
                height: 446px;
            }
        }

        .case-wrap {
            width: 350px;
            height: 446px;
            border: 1px solid #e5e5e5;
            border-radius: 8px;
            h3 {
                height: 44px;
                padding-left: 20px;
                background: #f8f8f8;
                border-radius: 8px 8px 0 0;
                display: flex;
                align-items: center;
                span {
                    color: #313131;
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0 8px;
                }
            }
            .num-case-wrap {
                margin: 0 10px;
            }
            h4 {
                text-align: center;
                padding: 35px 0 15px 0;
                font-size: 16px;
                font-weight: 700;
            }
            .num-case {
                margin-top: 10px;
                ul {
                    display: flex;
                    justify-content: space-between;
                }
                .item-num {
                    background-image: url("../../assets/images/num-bg.png");
                    background-repeat: no-repeat;
                    width: 50px;
                    height: 79px;
                    display: inline-block;
                    // margin: 0 3px 0 0;
                    color: #fff;
                    font-size: 44px;
                    font-weight: 700;
                    vertical-align: middle;
                    line-height: 79px;
                    border-radius: 3px;
                    text-align: center;
                }
            }
            .case-img {
                width: 326px;
                height: 83px;
                margin-top: 35px;
                border-radius: 5px;
            }
            .phone-img {
                margin-top: 30px;
                width: 283px;
                height: 65px;
            }
        }
    }

    .highlights-wrap {
        width: 100%;
        .list {
            width: 100%;
            // padding: 45px 0;
            // width: 1000px;
            // text-align: center;
            li {
                width: 279px;
                height: 123px;
                border: 1px solid #e5e5e5;
                border-top: 2px solid #e4393c;
                position: relative;
                overflow: visible;
                margin-top: 56px;
                text-align: center;
                border-radius: 5px;
                .present-icon {
                    position: absolute;
                    left: 99px;
                    top: -40px;
                    display: flex;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
                .name {
                    margin-top: 60px;
                    display: flex;
                    justify-content: center;
                    span {
                        display: inline-block;
                        width: 248px;
                        height: 40px;
                        line-height: 40px;
                        background: #f6f6f6;
                        color: #333;
                        font-size: 20px;
                        text-align: center;
                    }
                }
                .title {
                    font-weight: 800;
                    color: #000000;
                    font-size: 18px;
                }
                .hight-title {
                    color: #3c72ca;
                }
                .desc {
                    margin-top: 14px;
                    color: #464545;
                    font-size: 14px;
                    font-family: Adobe Heiti Std;
                }
            }
            li:nth-child(2),
            li:nth-child(4) {
                .hight-title {
                    color: #fb560a;
                }
            }
        }
    }

    // 关于我们
    .module-box {
        margin-top: 20px;
        border: 1px solid #e5e5e5;
        border-top: 2px solid #e4393c;
        border-radius: 5px;
        .box-title {
            height: 42px;
            line-height: 42px;
            border-bottom: 1px solid #e5e5e5;
            padding-left: 180px;
            h4 {
                float: left;
                margin-left: -160px;
                font-size: 16px;
                color: #e4393c;
                font-weight: 700;
            }
        }
        .progress-wrap {
            text-align: center;
            padding: 25px 0;
            font-size: 14px;
            color: #333;
            p {
                padding: 2px 0;
            }
            img {
                width: 1141px;
                height: 221px;
            }
        }
    }
    // 指南
    .courtmediate-wrap {
        .mediate-guide {
            height: 340px;
        }
        .box-title {
            height: 42px;
            line-height: 42px;
            border-bottom: 1px solid #e5e5e5;
            padding-left: 180px;
            .more {
                float: right;
                color: #333;
                margin-right: 16px;
                font-size: 16px;
            }
        }
        .guide-list-more {
            position: relative;
            padding: 11px 0;
            li {
                height: 33px;
                line-height: 33px;
                padding-left: 30px;
                background: url("../../assets/images/d.png") no-repeat 17px 15px;
                overflow: hidden;
                font-size: 14px;
                span {
                    float: right;
                    margin-right: 18px;
                    color: #999;
                    width: 120px;
                    text-align: center;
                }
                .text_list {
                    width: 400px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: block;
                    cursor: pointer;
                }
                a {
                    width: 434px;
                    height: 26px;
                    color: #333;
                }
            }
        }
    }

    .nav-wrap {
        width: 100%;
        .nav-list {
            padding: 30px 0 65px;
            width: 1000px;
            display: flex;
            justify-content: space-between;
            figure {
                width: 154px;
            }
            img {
                width: 115px;
                height: 115px;
                margin-bottom: 25px;
                box-shadow: 2px 2px 5px #cccfd0;
                border-radius: 50%;
            }
            .title {
                color: #fb560a;
                font-size: 16px;
                margin-bottom: 25px;
            }
            .desc {
                color: #323232;
                font-size: 14px;
                text-align: center;
                line-height: 20px;
                letter-spacing: 0.5px;
            }

            .hand:hover {
                .desc {
                    color: #3b79cc;
                }
                img {
                    transform: scale(1.2);
                    transition: all 0.6s;
                }
            }
        }
    }
    .adout-us-wrap {
        width: 100%;
        padding: 50px 0 80px;
        .about-wrap {
            width: 1000px;
            .img1 {
                width: 186px;
                height: 66px;
                margin-bottom: 58px;
            }
            .company-info {
                p {
                    text-indent: 24px;
                    line-height: 25px;
                    margin-right: 45px;
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1px;
                    width: 416px;
                }
                .info-img {
                    width: 443px;
                    height: 260px;
                    overflow: hidden;
                    img {
                        transition: all 0.6s;
                    }
                }
            }
            .company-info:hover {
                p {
                    color: #3b79cc;
                }
                .info-img {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
    .advantage-wrap {
        // background-image: url("../../assets/advantage.png");
        width: 100%;
        height: 705px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .img1 {
            width: 244px;
            height: 66px;
            margin-top: 93px;
            margin-bottom: 85px;
        }
        .list {
            li {
                width: 216px;
                height: 360px;
                margin-right: 20px;
                background: #f2f7fc;
                border-radius: 5px;
                .img2 {
                    width: 52px;
                    height: 52px;
                    margin-top: 30px;
                }
                .title {
                    margin-top: 10px;
                    font-weight: 800;
                    color: #000000;
                    font-size: 18px;
                }
                .desc {
                    margin: 15px 24px 0;
                    font-weight: 400;
                    font-size: 14px;
                    color: #323232;
                    line-height: 24px;
                }
            }
        }
    }
    .partner-wrap {
        padding: 90px 0 125px;

        .img1 {
            width: 260px;
            height: 66px;
            margin-bottom: 60px;
        }
        ul {
            width: 900px;
            .item-img {
                margin-right: 10px;
                margin-bottom: 17px;
            }
            .item-img:nth-child(4n) {
                margin-right: 0px;
            }
            .item-img:hover {
                transform: scale(1.05);
                transition: all 0.6s;
                // box-shadow: 2px 2px 5px #cccfd0;
            }
        }
    }
}
</style>
