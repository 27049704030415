<template>
    <div class="app-content">
        <div class="top-wrap" :class="fixed ? 'fiexdTop' : ''">
            <div class="header-top center">
                <p class="w1220">今天是：{{ dataTime }}</p>
            </div>
            <header class="center">
                <div class="w1220 nav-layout flex alin-center">
                    <img
                        src="@/assets/images/logo-new.png"
                        class="logo"
                        alt=""
                    />
                    <nav>
                        <ul class="center">
                            <!-- <li
                                class="item-nav hand"
                                :class="
                                    selectedNav == index + 1 ? 'high-color' : ''
                                "
                                v-for="(item, index) in navList"
                                :key="index"
                                @click="changeNav(item)"
                            >
                                 {{ item.text }}-{{ selectedNav }}--{{index+1}}
                            </li> -->
                            <li
                                class="item-nav hand"
                                :class="
                                    selectedNav == index + 1 ? 'high-color' : ''
                                "
                                v-for="(item, index) in navList"
                                :key="index"
                                @click="changeNav(item)"
                            >
                                {{ item.text }}
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
        <div class="main-content" ref="pronbit">
            <router-view />
        </div>
        <footer class="white-bg">
            <div class="footer">
                <nav class="footer-nav">
                    <ul class="nav-list flex">
                        <li
                            class="hand"
                            v-for="(item, index) in footNav"
                            :key="index"
                            @click="skipNav(item)"
                        >
                            <img :src="item.img" />
                            <div class="desc flex1">
                                {{ item.desc }}
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="adress-wrap">
                <p class="center">
                    <img src="@/assets/images/awa.png" class="phone" alt="" />
                    <span> &nbsp; 鄂ICP备2024034496号</span>
                </p>
                <p>地址：湖北省黄冈市麻城市南湖办事处金龙大道搜人学校三楼</p>
            </div>
        </footer>
    </div>
</template>
<script>
import { provide } from "vue";

export default {
    data() {
        return {
            fixed: false,
            navList: [
                {
                    text: "首页",
                    url: "/",
                    typeId: "1",
                },
                {
                    text: "调解中心",
                    url: "/mediation-center",
                    typeId: "10000059",
                },
                {
                    text: "调解指南",
                      url: "/mediation-guide",
                    typeId: "10000055",
                },
                {
                    text: "调解员名录",
                 
                      url: "/mediator-list",
                    typeId: "10000056",
                },
                {
                    text: "法院导航",
                    url: "/court",
                },
            ],
            selectedNav: 1,
            footNav: [
                {
                    img: require("@/assets/images/footer-icon01.png"),
                    title: "消费金融平台",
                    desc: "中国庭审直播公开",
                    url: "http://tingshen.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon02.png"),
                    title: "小额贷款平台",
                    desc: "中国审判流程信息公开",
                    url: "https://splcgk.court.gov.cn/gzfwww/",
                },
                {
                    img: require("@/assets/images/footer-icon03.png"),
                    title: "风险决策平台",
                    desc: "中国裁判文书公开",
                    url: "https://wenshu.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon04.png"),
                    title: "风险决策平台",
                    desc: "中国执行信息公开",
                    url: "http://zxgk.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon05.png"),
                    title: "P2P网贷平台",
                    desc: "全国法院统一达到平台",
                    url: "https://songda.court.gov.cn/",
                },
                {
                    img: require("@/assets/images/footer-icon06.png"),
                    title: "风险决策平台",
                    desc: "最高人民法院诉讼服务网",
                    url: "https://ssfw.court.gov.cn/ssfww/",
                },
            ],
            dataTime: "",
            typeId: sessionStorage.getItem("typeId"),
        };
    },

    // 监听,当路由发生变化的时候执行
    watch: {
        $route: "getPath",
    },

    mounted() {
        window.addEventListener("scroll", this.handleScrollx, true);
        this.getTime();
    },
    methods: {
        getTime() {
            // 年
            const year = new Date().getFullYear();
            // 月份是从0月开始获取的，所以要+1;
            const month =
                new Date().getMonth() + 1 < 10
                    ? "0" + (new Date().getMonth() + 1)
                    : new Date().getMonth() + 1;
            // 日
            const day =
                new Date().getDate() < 10
                    ? "0" + new Date().getDate()
                    : new Date().getDate();
            //时
            let hour =
                new Date().getHours() < 10
                    ? "0" + new Date().getHours()
                    : new Date().getHours();
            //分
            let minute =
                new Date().getMinutes() < 10
                    ? "0" + new Date().getMinutes()
                    : new Date().getMinutes();
            //秒
            let second =
                new Date().getSeconds() < 10
                    ? "0" + new Date().getSeconds()
                    : new Date().getSeconds();

            //获取星期
            let myDate = new Date();
            let week = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            )[myDate.getDay()];

            // this.form.creatorTime = year + '-' + month + '-' + day
            let creatorTime =
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                // hour +
                // ":" +
                // minute +
                // ":" +
                // second +
                // " " +
                week;

            this.dataTime = creatorTime;
        },
        getPath() {
            //清除上一次滚动条的位置
            document.documentElement.scrollTop = 0;
            let path = this.$route.path;
            // let typeId = sessionStorage.getItem("typeId");
            // console.log("获取typeId 判断是否高亮导航", typeId);
            if (path == "/") {
                this.selectedNav = 1;
            } else if (path.indexOf("mediation-center") != -1) {
                this.selectedNav = 2;
            } else if (path.indexOf("mediation-guide") != -1) {
                this.selectedNav = 3;
            } else if (path.indexOf("mediator-list") != -1) {
                this.selectedNav = 4;
            } else if (path.indexOf("court") != -1) {
                this.selectedNav = 5;
            } else if (path.indexOf("about-us") != -1) {
                this.selectedNav = 6;
            }
        },
        // 获取typeId 判断是否高亮导航
        getTypeId(value) {
            console.log("获取typeId 判断是否高亮导航", value);
            if (value == "10000056") {
                this.selectedNav = 4;
            } else if (value == "10000059") {
                this.selectedNav = 2;
            } else if (value == "10000055") {
                this.selectedNav = 3;
            } else {
                this.selectedNav = "";
            }
            //   console.log("this", this);

            console.log(" this.selectedNav", this.selectedNav);
        },

        changeNav(item) {
            this.$router.push({ path: item.url });
        },

        handleScrollx() {
            if (window.pageYOffset >= 108) {
                this.fixed = true;
            }
            if (window.pageYOffset < 108) {
                this.fixed = false;
            }
        },
        skipNav(item) {
            window.open(item.url);
        },
    },
};
</script>
<style lang="less" scoped>
.w1220 {
    width: 1220px;
}
.app-content {
    width: 100%;
    height: 100%;
    font-family: PingFang SC;
    background-color: #f5f5f5;
    position: relative;
 
    .top-wrap {
        width: 100%;

        .header-top {
            width: 100%;
            height: 30px;
            line-height: 30px;
            background: #961113;
            color: #fff;
             font-size: 14px;
        }

        //    background-color: rgba(0, 0, 0, 0.08);
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //    z-index: 10;
        //    color: #ffffff;
        //    transition: all 1s;

        header {
            background-image: url("./assets/images/top-bg.png");
            background-repeat: repeat-x;
            height: 100px;
            .nav-layout {
                height: 100px;
                background: url(./assets/images/header-bg.png) 100% 0 no-repeat;
                justify-content: space-between;
            }
        }
        .logo {
            // width: 398px;
            // height: 45px;
            width: 433px;
            height: 44px;
            //   margin-right: 164px;
        }
        ul {
            height: 100%;
        }
        .item-nav {
            margin-right: 60px;
            // width: 142px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            height: 100px;
            line-height: 100px;
            position: relative;
        }
        .item-nav:last-child {
            margin-right: 0;
        }

        .high-color:before {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            background: #fff;
            width: 90px;
            height: 4px;
            border-radius: 5px;
            box-sizing: border-box;
            animation: adwidth 0.5s linear forwards;
        }
    }
    @keyframes adwidth {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    // 固定顶部导航

    .fiexdTop {
        // position: fixed;
        // z-index: 10;
        // top: 0px;

        //    background: #fff;
        //    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        //    .item-nav {
        //        color: #3b79cc;
        //    }
        //    .high-color:before {
        //        background: #3b79cc;
        //    }
    }
    .main-content {
        //    width: 1220px;
        // width: 1180px;
        // margin: 0 auto 20px;
        // padding: 20px;
    }
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 192px;
        background: url(./assets/images/footer-bg.png) no-repeat bottom;
        border-bottom: 5px solid #e4393c;

        .footer-nav {
            width: 1200px;
            display: flex;

            .nav-list {
                width: 100%;
                justify-content: space-around;
            }

            li {
                width: 165px;
                //  height: 132px;
                border: 1px solid #d6d6d6;
                //  text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                //  justify-content: center;
                padding: 20px 0;
            }
            figure {
                width: 154px;
            }
            img {
                width: 47px;
                height: 47px;
                //  margin-bottom: 20px;
                //  box-shadow: 2px 2px 5px #cccfd0;
                border-radius: 50%;
            }

            .desc {
                color: #323232;
                font-size: 14px;
                text-align: center;
                margin-top: 5px;
                padding: 0 40px;
            }
            .btn {
                border-radius: 15px;
                font-size: 14px;
                padding: 4px 20px;
                margin-top: 10px;
                border: 1px solid #cfcfcf;
            }
            //   li:nth-child(1):hover {
            //       .btn {
            //           color: #ff8224;
            //           border: 1px solid #ff8225;
            //       }
            //   }
            //   li:nth-child(2):hover {
            //       .btn {
            //           color: #4fd008;
            //           border: 1px solid #4fd008;
            //       }
            //   }
            //   li:nth-child(3):hover {
            //       .btn {
            //           color: #09c3ff;
            //           border: 1px solid #09c3ff;
            //       }
            //   }
            //   li:nth-child(4):hover {
            //       .btn {
            //           color: #955cf1;
            //           border: 1px solid #955cf1;
            //       }
            //   }
        }

        // 页脚
        .footer-content {
            width: 1200px;
            .mt10 {
                margin-top: 10px;
            }
            .company-info {
                padding: 47px 40px 36px;
                border-bottom: 0.5px solid #6b6a6a;
                .left-wrap {
                    .item {
                        font-weight: 400;
                        color: #cfcfcf;
                        font-size: 14px;

                        .phone {
                            width: 16px;
                            height: 16px;
                            margin-right: 15px;
                        }
                        .adress {
                            width: 15px;
                            height: 19px;
                            margin-right: 15px;
                        }
                    }
                }
                .right-wrap {
                    .code {
                        width: 100px;
                        height: 100px;
                        margin-right: 20px;
                    }
                }
            }

            .text {
                font-weight: 400;
                color: #f2f7fc;
                font-size: 12px;
            }
        }
    }
    .adress-wrap {
        width: 100%;
        color: #666;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        p:nth-child(1) {
            margin-bottom: 10px;
            color: #313131;
        }
        img{
            width: 20px;
            height: 20px;
        }
    }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 1000px) {
    /*当屏幕尺寸小于1200px时，应用下面的CSS样式*/
    .page-content {
        // width: 90% !important;
        // border-color: pink;
        // font-size: 24px !important;
       
    }
}
</style>
